<template>
  <div  class="common-layout" style="height:100%">
    <el-container style="height:100%">
      <el-header style="background-color: rgba(40, 122, 180, 1);height:5%;min-height:47px">
        <div style="height:60%;display: flex;justify-content: space-between;align-items: center;">
          <div style="font-size:18px;display: flex;justify-content:center;align-items: center;">
            &nbsp;
            <img src="@/assets/icons/logo.png" style="width:25px">
            &nbsp;
            <span style="line-height:200%;color:#fff">美加美财务</span>
          </div>
          <div>
            <router-link :to="{name:'oneself'}" style="color:#fff;margin-right: 30px;font-size: 14px;cursor: pointer;">
              <svg class="icon" style="color:#fff;vertical-align: sub;" width="20" height="20" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-042ca774=""><path fill="currentColor" d="M628.736 528.896A416 416 0 01928 928H96a415.872 415.872 0 01299.264-399.104L512 704l116.736-175.104zM720 304a208 208 0 11-416 0 208 208 0 01416 0z"></path></svg>
              个人
            </router-link>
            <el-button type="danger" @click="quit()" size='mini'>退出</el-button>
          </div>
        </div>
        <div style="height:40%;border-top:1px solid #38adf1;color:#ffffff;font-size: 13px">
          <span>技术支持QQ:2638632674</span>
          &nbsp;&nbsp;丨&nbsp;&nbsp;
          <span>售后电话:19136114361（微信同号）</span>
          &nbsp;&nbsp;丨&nbsp;&nbsp;
          <span>商城售后:19381614361（微信同号）</span>
          &nbsp;&nbsp;丨&nbsp;&nbsp;
          <span>投诉电话:13320998840</span>
        </div>

      </el-header>
      <el-container  style="height:95%">
        <el-aside style="background-color: rgba(40, 122, 180, 1);box-shadow: -15px -11px 0.3rem 0 rgb(0 0 0 / 14%) inset;" width="150px">
          <div class="home-list">
            <router-link class="routerLiink" :to="{name:'report',query:{dateMin:new Date().getFullYear()+'-'+((new Date().getMonth()+1)>9?(new Date().getMonth()+1):('0'+(new Date().getMonth()+1)))+'-'+(new Date().getDate()>9?new Date().getDate():('0'+new Date().getDate()))}}">营业报告</router-link> 
            <router-link class="routerLiink" to="/home/vipForm">会员报表</router-link> 
            <!-- <router-link class="routerLiink" to="/home/system">系统续费</router-link>  -->
          </div>
        </el-aside>
        <el-main style="position: relative;width:100%;min-width:600px;height:100%;overflow:auto;box-sizing: border-box;padding:0">
          <router-view/>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import {ref} from'vue'
import {postData} from '@a'
import { useRouter, useRoute } from 'vue-router'
export default {
  setup (){
    let router = useRouter()
    let route = useRoute()
    let shopName = ref("")
    const quit = ()=> {
      window.sessionStorage.setItem('logStatue',false)
      router.replace({name: 'log'})
    }
    const info = async()=>{
      let param = {}
      param.shopIds = window.localStorage.getItem('shopIds')
      param.phone = window.localStorage.getItem('mobile')
      param.mobile = ''
      try {
        if(window.localStorage.getItem('shopIds').split(",").length>1){
          param.shopIds = ''
          param.mobile = window.localStorage.getItem('mobile')
        }
        let res = await postData('shop/getShopName.json',param)
        let shopNameText = '全部店铺'
        if(res.data && res.data.shopName) shopNameText = res.data.shopName
        window.localStorage.setItem('shopName',shopNameText)
        window.localStorage.setItem('userName',res.data.userName)
        shopName.value = shopNameText
      } catch (err) {
        console.log(err)
      }
    }
    console.log(route)
    info()
    const infoCell = async()=>{
      let param = {}
      try {
        let res = await postData('public/enum_setting.json',param)
        window.localStorage.setItem('cell',JSON.stringify(JSON.parse(res.data).goods_unit))
      } catch (err) {
        console.log(err)
      }
    }
    infoCell()
    return {
      quit,shopName,
    }
  },
}
</script>
<style scoped>
.routerLiink{
  font-family: 'Arial Normal', 'Arial';
  /* font-weight: 600; */
  font-style: normal;
  font-size: 18px;
  color: #DEDEDEF2;
  line-height: 60px;
  text-align: center;

}
.router-link-active{
  background-color: #1B5E84;
  color: #fff;
  font-weight: 700;
}
.home-list{
  text-align: center;
  padding-top:15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
